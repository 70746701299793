import { JsonObject } from 'type-fest';
import { Logger } from '@/Logger';

type beaconParams = {
  [key: string]: string | number;
};

export interface ObserverOptions {
  intersectionObserver?: IntersectionObserverInit;
  viewableRatio?: number;
}

export interface BvwBeacon {
  start_ts: number;
  evt: string;
  v: number;
  tiv: number;
  vwbl: 1 | 0;
  vwbl_ts: number;
  cfg_vwbl_ratio: number;
}

export interface MeasurerOptions {
  onIntersecting: (isIntersecting: boolean) => void;
  onVisibilityChange?: (hidden: boolean, isIntersecting: boolean) => void;
  onBeforeSendBeacon?: (becauseOf: string, isIntersecting: boolean) => { [index: string]: string | number };
  beaconUrl: string;
  tivContinuousDuration?: number;
  tivMaxDuration?: number;
  beaconIntervalDelay?: number;
  viewableRatio?: number;
  observerOptions?: IntersectionObserverInit;
}

export enum MeasurerErrors {
  INTERSECTING = 'The element cannot be seen by the user.',
  IOBSRVR_NOT_FOUND = 'IntersectionObserver API is not available',
  BEACON_URL_MSSING = 'The beacon url is invalid'
}

export class Measurer {
  /**
   * The timestamp when the mesurer starts
   * @todo Probably useless
   */
  private _startTs = 0;

  /**
   * Element to observe;
   */
  private _element!: HTMLElement;

  /**
   * The IntersectionObserver object to handle measurement
   */
  private _observer: IntersectionObserver;

  /**
   * Indicate the data has been changed since the last beacon.
   */
  private _dirty = false;

  /**
   * Maintened by the IntersectionObserver callback
   */
  private _isIntersecting = false;

  /**
   * The mesured adUnit is considered as viewable as it matches
   * min percent area in the viewport `_viewableRatio` and reached the
   * continuous tiv duration `_tivContinuousDuration`
   */
  private _viewable = false;

  /**
   * The timestamp the viewable flag has been set
   */
  private _viewableTs = 0;

  /**
   * The minimum percent of area in viewport to compute tiv
   */
  private _viewableRatio = 0.5;

  /**
   * The timeout Id used to set the `viewable` flag.
   */
  private _viewableTmtId: number | false = false;

  /**
   * Total exposure duration of the adunit, in milliseconds.
   */
  private _tiv = 0;

  /**
   * Timestamp used to compute tiv
   */
  private _tivLastUpdTs = 0;

  /**
   * Continous exposure duration of an adUnit in the viewport to be
   * considered as viewable (in combinaison with _wiewableRatio).
   */
  private _tivCntnsDrtn = 1000;

  /**
   * The beacon url e.g. https://script.4dex.io/bvw.gif?foo=bar
   */
  private _beaconUrl!: string;

  /**
   * Beacon version incremented each time a beacon is sent.
   */
  private _beaconVersion = 0;

  /**
   * The id of the registred timeout to send data on `exp_chg`
   * See setExpChgBcnTmt()
   */
  private _beaconIntrvlId: number | false = false;

  /**
   * Delay between 2 `exp_chg` beacons
   */
  private _beaconIntrvlDly = 5000;

  /**
   * Limit to avoid to send useless `exp_chg` beacons.
   */
  private _beaconTivMaxDrtn = 60000;

  private _onVisibilityChange!: (hidden: boolean, isIntersecting: boolean) => void;

  private _onBeforeSendBeacon!: (becauseOf: string, isIntersecting: boolean) => JsonObject;

  /**
   * Logger instance
   */
  logger: Logger;

  constructor(element: HTMLElement, options: MeasurerOptions) {
    if (!window.IntersectionObserver) {
      throw new Error(MeasurerErrors.IOBSRVR_NOT_FOUND);
    }

    this.logger = Logger.getInstance();

    this.logger.debug('New measurer with' + JSON.stringify(options));

    this._element = element;

    if (options?.tivContinuousDuration) {
      this._tivCntnsDrtn = options.tivContinuousDuration;
    }

    if (options?.tivMaxDuration) {
      this._beaconTivMaxDrtn = options.tivMaxDuration;
    }

    if (options?.beaconIntervalDelay) {
      this._beaconIntrvlDly = options.beaconIntervalDelay;
    }

    if (options?.viewableRatio) {
      this._viewableRatio = options.viewableRatio;
    }

    if (options?.onVisibilityChange) {
      this._onVisibilityChange = options.onVisibilityChange;
    }

    if (options?.onBeforeSendBeacon) {
      this._onBeforeSendBeacon = options.onBeforeSendBeacon;
    }

    /**
     * @todo ensure beacon url is a URL
     */
    if (!options.beaconUrl) {
      throw new Error(MeasurerErrors.BEACON_URL_MSSING);
    }
    this._beaconUrl = options.beaconUrl;

    this._observer = this.buildObserver(options.onIntersecting, options?.observerOptions);
    this._observer.observe(element);

    this.bindEvents();

    this._startTs = Date.now();

    this.sendBeacon({ becauseOf: 'start', force: true });
  }

  private bindEvents() {
    window.document.addEventListener('visibilitychange', this.onVisibilityChange, false);
  }

  private unbindEvents(): void {
    window.document.removeEventListener('visibilitychange', this.onVisibilityChange);
  }

  private onVisibilityChange = (): void => {
    try {
      if (document.hidden) {
        this._onVisibilityChange(true, this._isIntersecting);
        this.pause();
        this.unbindIntervalBeacon();
        this.sendBeacon({ becauseOf: 'visibilitychange', force: true, withBeaconApi: true });
      } else if (this._isIntersecting) {
        this._onVisibilityChange(false, this._isIntersecting);
        this.start();
      }
    } catch (err) {
      // console.log(err)
    }
  };

  private buildObserver(onIntersecting: (_isIntersecting: boolean) => void, options?: IntersectionObserverInit) {
    return new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        this.logger.debug(`Measurer, intersect ratio with: ${entry.intersectionRatio}`);
        try {
          /**
           * Add a pseudo security to insure to trigger the correct behavior if the
           * intersectionRatio is really near. e.g.: `intersect ratio 0.6919999718666077`
           */
          if (entry.intersectionRatio >= this._viewableRatio) {
            this._isIntersecting = true;

            onIntersecting(this._isIntersecting);

            if (!document.hidden) {
              this.start();
            }
          } else {
            this._isIntersecting = false;

            onIntersecting(this._isIntersecting);

            this.pause();
          }
        } catch (err) {
          this.logger.error(err);
        }
      });
    }, options);
  }

  private startTiv(): void {
    if (document.hidden || !this._isIntersecting) {
      throw new Error(MeasurerErrors.INTERSECTING);
    }

    if (this._isIntersecting) {
      this._tivLastUpdTs = Date.now();
      this.bindIntervalBeacon();
    }
  }

  private updateTiv(ts: number): void {
    // important, do not do anything if not viewable.
    if (!this._viewable) {
      return;
    }

    // The element is intersecting, so the lastUpd timestamp is set,
    // we can increment the TIV.
    if (this._tivLastUpdTs > 0) {
      const increment = ts - this._tivLastUpdTs;

      this._tiv += increment;

      this._tivLastUpdTs = ts;
      this._dirty = true;
    }
  }

  private stopTiv(): void {
    this.updateTiv(Date.now());
    this._tivLastUpdTs = 0;
  }

  private trackViewable(): void {
    if (document.hidden || !this._isIntersecting) {
      throw new Error(MeasurerErrors.INTERSECTING);
    }

    if (this._viewableTmtId || this._viewable) {
      return;
    }

    this._viewableTmtId = window.setTimeout(() => {
      this.setViewable(Date.now());
    }, this._tivCntnsDrtn);
  }

  private untrackViewable(): void {
    if (this._viewableTmtId) {
      window.clearTimeout(this._viewableTmtId);
    }
    this._viewableTmtId = false;
  }

  private setViewable(ts: number): void {
    this.untrackViewable();

    this._viewable = true;
    this._viewableTs = ts;
    this._tivLastUpdTs = ts - this._tivCntnsDrtn;
    this.updateTiv(ts);

    this.sendBeacon({ becauseOf: 'vwbl' });
  }

  private bindIntervalBeacon(): void {
    if (this._beaconIntrvlId) {
      return;
    }

    this._beaconIntrvlId = window.setInterval(() => {
      if (this._viewable && this._tiv <= this._beaconTivMaxDrtn) {
        if (this._isIntersecting) {
          this.updateTiv(Date.now());
        }
        this.sendBeacon({ becauseOf: 'exp_chg' });
      }
    }, this._beaconIntrvlDly);
  }

  private unbindIntervalBeacon(): void {
    if (this._beaconIntrvlId) {
      window.clearTimeout(this._beaconIntrvlId);
      this._beaconIntrvlId = false;
    }
  }

  private encodeQueryParam(key: string, value: string | string[], separator = '|') {
    if (Array.isArray(value)) {
      value = value.join(separator);
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }

  private sendWithBeaconApi(url: string): boolean {
    return navigator.sendBeacon ? navigator.sendBeacon(url) : false;
  }

  private sendWithXHR(url: string): void {
    const onError = () => {
      this.sendWithBeaconApi(url);
    };

    let xhr: XMLHttpRequest;

    try {
      xhr = new XMLHttpRequest();
      xhr.onerror = onError;

      // Important: the 3rd parameter of open() is the `async` flag.
      // The default value is `true`.
      // The beacon must ALWAYS been sent in an async mode.
      xhr.open('post', url);
      xhr.send();
    } catch (e) {
      // console.log(e);
    }
  }

  get isIntersecting() {
    return this._isIntersecting;
  }

  start(): void {
    if (document.hidden || !this._isIntersecting) {
      throw new Error(MeasurerErrors.INTERSECTING);
    }

    this.trackViewable();
    this.startTiv();
  }

  pause(): void {
    this.untrackViewable();
    this.stopTiv();
  }

  detach(): void {
    this._observer.unobserve(this._element);
    this.unbindIntervalBeacon();
    this.unbindEvents();
  }

  sendBeacon(options: { becauseOf: string; force?: boolean; withBeaconApi?: boolean }): void {
    const { becauseOf, withBeaconApi, force } = options;

    if (!this._dirty && !force) {
      // console.log('Consider not sendind beacon');
      return;
    }

    if (this._tiv >= this._beaconTivMaxDrtn) {
      this.unbindIntervalBeacon();
      this.unbindEvents();
    }

    this._beaconVersion++;

    const customParams = this._onBeforeSendBeacon ? this._onBeforeSendBeacon(becauseOf, this._isIntersecting) : {};

    const beaconData: beaconParams & BvwBeacon = {
      start_ts: this._startTs,
      evt: becauseOf,
      v: this._beaconVersion,
      tiv: Math.min(this._tiv, this._beaconTivMaxDrtn),
      vwbl: this._viewable === true ? 1 : 0,
      vwbl_ts: this._viewableTs,
      cfg_vwbl_ratio: this._viewableRatio,
      ...customParams
    };

    const queryParams: string[] = [];

    Object.keys(beaconData).forEach((value: any) => {
      queryParams.push(this.encodeQueryParam(value, Reflect.get(beaconData, value)));
    });

    const url = `${this._beaconUrl}&${queryParams.join('&')}`;

    if (withBeaconApi) {
      this.sendWithBeaconApi(url);
    } else {
      this.sendWithXHR(url);
    }

    this._dirty = false;
  }
}
