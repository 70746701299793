import defaultsDeep from 'lodash/defaultsDeep';
import { OutstreamPlayerConfig, OutstreamPlayerConfigOptions, OutstreamPlayerLazyLoader } from '@/OustreamPlayer';
import { BidResponse } from '@/index';

/**
 * Outstream player configuration
 * Used to build the player configuration by merging default and option
 */
export class OutstreamConfig implements Partial<Pick<Required<OutstreamPlayerConfigOptions>, 'lazyLoader'>> {
  width: number;

  height: number;

  vastTimeout: number;

  maxAllowedVastTagRedirects: number;

  allowVpaid: boolean;

  autoPlay: boolean;

  preload: boolean;

  mute: boolean;

  adText: string;

  lazyLoader?: OutstreamPlayerLazyLoader;

  constructor(bid: BidResponse, options?: OutstreamPlayerConfigOptions) {
    const _defaults: OutstreamPlayerConfigOptions = {
      width: 600,
      height: 480,
      vastTimeout: 5000,
      maxAllowedVastTagRedirects: 3,
      allowVpaid: false,
      autoPlay: true,
      preload: true,
      mute: true,
      adText: ''
    };

    const configFromBid = this.configFromBid(bid);

    const config: OutstreamPlayerConfig = defaultsDeep(options, configFromBid, _defaults);

    this.width = config.width;
    this.height = config.height;
    this.vastTimeout = config.vastTimeout;
    this.maxAllowedVastTagRedirects = config.maxAllowedVastTagRedirects;
    this.allowVpaid = config.allowVpaid;
    this.autoPlay = config.autoPlay;
    this.preload = config.preload;
    this.mute = config.mute;
    this.adText = config.adText;

    if (config.lazyLoader) {
      this.lazyLoader = config.lazyLoader;
    }
  }

  configFromBid(bid: BidResponse): OutstreamPlayerConfigOptions {
    const config: OutstreamPlayerConfigOptions = {};

    const { lazyLoader, width, height } = bid;

    if (lazyLoader) {
      config.lazyLoader = lazyLoader;
    }

    if (width) {
      config.width = width;
    }

    if (height) {
      config.height = height;
    }

    return config;
  }
}
