export type Log = {
  name: keyof Console;
  msg: string;
};

export class Logger {
  static readonly QUERY_PARAM_FOR_DEBUG_LEVEL = 'adg-otstrm-plyr-debug';
  static readonly LOG_PREFIX = 'ADG-OTSTRM-PLYR-';

  // Logging Level
  static readonly INFO_LEVEL = 1;
  static readonly ERROR_LEVEL = 2;
  static readonly WARN_LEVEL = 3;
  static readonly DEBUG_LEVEL = 4;
  static readonly LOG_LEVEL = 5;

  private debugLevel = 0;

  protected static instance: Logger | null = null;

  private constructor() {
    // Set debug level
    this._setDebugLevelFromUrlParams();
  }

  static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  log(msg: string): void {
    if (this.debugLevel >= Logger.LOG_LEVEL) {
      this.consoleLog({ name: 'log', msg });
    }
  }

  debug(msg: string): void {
    if (this.debugLevel >= Logger.DEBUG_LEVEL) {
      this.consoleLog({ name: 'debug', msg });
    }
  }

  warn(msg: string): void {
    if (this.debugLevel >= Logger.WARN_LEVEL) {
      this.consoleLog({ name: 'warn', msg });
    }
  }

  error(msg: string): void {
    if (this.debugLevel >= Logger.ERROR_LEVEL) {
      this.consoleLog({ name: 'error', msg });
    }
  }

  info(msg: string): void {
    if (this.debugLevel >= Logger.INFO_LEVEL) {
      this.consoleLog({ name: 'info', msg });
    }
  }

  consoleLog(log: Log): void {
    // eslint-disable-next-line no-console
    console[log.name](`${Logger.LOG_PREFIX} ${log.name.toUpperCase()}: ${log.msg}`);
  }

  private _setDebugLevelFromUrlParams(): void {
    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.has(Logger.QUERY_PARAM_FOR_DEBUG_LEVEL)) {
      // Get the debug level from the query string.
      const debugLevelFromParams = queryParams.get(Logger.QUERY_PARAM_FOR_DEBUG_LEVEL);
      if (typeof debugLevelFromParams === 'string') {
        const level = parseInt(debugLevelFromParams, 10);
        if (!isNaN(level)) {
          this.debugLevel = Math.abs(level);
        }
      }
    }
  }
}
