import { Logger } from '@/Logger';
import { OutstreamPlayer, OutstreamPlayerConfigOptions } from '@/OustreamPlayer';

export interface Adagio {
  outstreamPlayer?:
    | OutstreamPlayer
    | ((bid: BidResponse, elementId: string, config?: OutstreamPlayerConfigOptions) => OutstreamPlayer);
}

export interface AdagioWindow extends Window {
  ADAGIO?: Adagio;
}

export type BidParams = {
  [key: string]: any;
  adUnitElementId: string;
};

export type BidOutstreamLazyLoader = Pick<Required<IntersectionObserverInit>, 'rootMargin' | 'threshold'> & {
  viewableRatio: number;
};

export type BidOutstream = {
  impUrl: string;
  bvwUrl: string;
  pauseMode: 'completion' | 'attention';
  rendererUrl?: string;
  lazyLoader?: BidOutstreamLazyLoader;
};

export type BidResponse = {
  [key: string]: any;
  params: BidParams[];
  outstream?: BidOutstream;
};

const w = window as AdagioWindow;

w.ADAGIO = w.ADAGIO || {};

// eslint-disable-next-line no-unused-vars
w.ADAGIO.outstreamPlayer =
  w.ADAGIO.outstreamPlayer ||
  ((bid: BidResponse, elementId?: string, config?: OutstreamPlayerConfigOptions) => {
    Logger.getInstance().log('Inside window.ADAGIO.outstreamPlayer.');
    return new OutstreamPlayer(bid, elementId, config);
  });
