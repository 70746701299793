import defaultsDeep from 'lodash/defaultsDeep';
import fluidPlayer, { FluidPlayerInterface, FluidPlayerOptions as IFluidPlayerOptions } from 'fluid-player';
import { BidResponse } from '@/index';
import { Logger } from '@/Logger';
import { GenericPlayer } from '@/interfaces/GenericPlayer.interface';
import { FluidPlayerOptions } from './FluidPlayerOptions';
import '~/fluid-player/src/css/fluidplayer.css';
import './fluid-player.css';

export class FluidPlayer implements GenericPlayer {
  private _isVideoPlaying: boolean;

  bid!: BidResponse;

  elementId!: string;

  element!: HTMLElement;

  genericConfiguration!: unknown;

  fluidPlayerConfig!: IFluidPlayerOptions;

  player!: FluidPlayerInterface;

  logger: Logger;

  constructor() {
    this.logger = Logger.getInstance();

    this.logger.debug('Inside fluid player constructor.');
    this.logger.log(`Fluid player object: ${fluidPlayer}`);

    this._isVideoPlaying = false;
  }

  get isVideoPlaying(): boolean {
    return this._isVideoPlaying;
  }

  generatePlayerConfig<T>(bid: BidResponse, elementId: string, configuration: T): void {
    this.logger.debug('Inside FluidPlayer.generatePlayerConfig');
    this.bid = bid;

    // Check if valid elementId
    const htmlElement = document.getElementById(elementId);
    if (!htmlElement) {
      this.logger.error('No element present with element ID: ' + elementId);
      throw new Error('Please provide a valid element ID.');
    }

    this.elementId = elementId;

    this.element = htmlElement;

    this.genericConfiguration = configuration;
    this.logger.log('FluidPlayer-generatePlayerConfig' + JSON.stringify(this.genericConfiguration));

    const vastTag = this._extractVastTag(bid);

    if (!vastTag) {
      this.logger.error('VastTag cannot be found');
      throw new Error('Vast Tag missing');
    }

    /**
     * @todo ajouter les params venant le genericConfiguration
     */
    const options: IFluidPlayerOptions = defaultsDeep(this.genericConfiguration, {
      vastOptions: {
        adList: [
          {
            roll: 'preRoll',
            vastTag
          }
        ]
      }
    });

    // if (
    //   Array.isArray(this.bid?.renderer?.config?.playbackmethod) &&
    //   this.bid.renderer.config.playbackmethod.length === 1
    // ) {
    //   options.layoutControls = options.layoutControls || {};
    //   console.log('PLAYBACK METH', this.bid.renderer.config.playbackmethod);
    //   switch (parseInt(this.bid.renderer.config.playbackmethod[0], 10)) {
    //     case 5:
    //       options.layoutControls.mute = false;
    //       break;
    //     default:
    //       options.layoutControls.mute = true;
    //       break;
    //   }
    // }

    this.fluidPlayerConfig = new FluidPlayerOptions(options);
    this.logger.log('this.fluidPlayerConfigObj' + JSON.stringify(this.fluidPlayerConfig));
  }

  setupPlayer(videoPlayerId: string): void {
    this.logger.debug('Insid FluidPlayer.setupPlayer with player ID: ' + videoPlayerId);

    this.element.style.display = 'block';

    const playerHtmlElement = document.getElementById(videoPlayerId);

    if (!playerHtmlElement) {
      this.logger.error('No element is present with videoPlayeId: ' + videoPlayerId);
      throw new Error('Please provide a valid video player ID.');
    }

    playerHtmlElement.style.display = 'block';

    this.player = fluidPlayer(videoPlayerId, this.fluidPlayerConfig);

    this._registerFluidPlayerCallbacks();
  }

  play(): void {
    this.logger.debug('Inside FluidPlayer.play');
    this.player.play();
  }

  pause(): void {
    this.logger.debug('Inside FluidPlayer.pause');
    this.player.pause();
  }

  _registerFluidPlayerCallbacks(): void {
    this.player.on('pause', () => {
      this.logger.log('Video is now paused.');
      this._isVideoPlaying = false;
    });

    this.player.on('playing', () => {
      this.logger.log('Video is now playing - playing.');
      this._isVideoPlaying = true;
    });

    this.player.on('ended', () => {
      this.logger.log('Video is now ended.');
      this._isVideoPlaying = false;

      if (this.player) {
        // Exit fullscreen mode
        this.logger.log('Exit full screen mode to continue using normal mode.');
        // utils.closeFullscreen();
      }
    });

    this.player.on('play', () => {
      this.logger.log('Video is playing - play.');
    });

    this.player.on('seeked', () => {
      this.logger.log('Video is now seeked.');
    });

    this.player.on('theatreModeOn', () => {
      this.logger.log('Theatre mode is enabled.');
    });

    this.player.on('theatreModeOff', () => {
      this.logger.log('Theatre mode is disabled.');
    });

    // this.player.on('timeupdate', (time: number) => {
    //   // This event doesn't work (in outstream context only?)
    //   // and returns always time = 0
    //   this.logger.log('Time updated with value: ' + time);
    // });
  }

  _extractVastTag(bid: BidResponse): string | undefined {
    let vastTag;

    if (bid.vastUrl) {
      this.logger.log('Bid object contains bid.vastUrl with value: ' + bid.vastUrl);
      vastTag = bid.vastUrl;
    } else if (bid.vastXml) {
      this.logger.log('Bid object contains bid.vastXml with value: ' + bid.vastXml);
      // No direct support for VAST tag, so creating a Data URI
      this.logger.log('VAST type creative: ' + bid.vastXml);
      vastTag = 'data:text/xml;charset=utf-8;base64,' + btoa(bid.vastXml.replace(/\\"/g, '"'));
    } else if (bid.ad) {
      this.logger.log('Bid object contains bid.ad with value: ' + bid.ad);
      if (bid.ad.search(/<VAST/gi) !== -1) {
        // No direct support for VAST tag, so creating a Data URI
        this.logger.log('VAST type creative: ' + bid.ad);
        vastTag = 'data:text/xml;charset=utf-8;base64,' + btoa(bid.ad.replace(/\\"/g, '"'));
      } else if (bid.ad.startsWith('http')) {
        this.logger.log('Ad tag type creativrr: ' + bid.ad);
        vastTag = bid.ad;
      }
    }

    if (!vastTag) {
      this.logger.error("Bid object doesn't contain bid.ad or bid.vastXml object, so no VAST tag or XML is available.");
    }

    return vastTag;
  }
}
