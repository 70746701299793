import { VastOptions } from 'fluid-player';
import { PartialDeep } from 'type-fest';
import { BidResponse, BidOutstreamLazyLoader } from '@/index';
import { Logger } from '@/Logger';
import { Measurer } from '@/Measurer';
import { OutstreamConfig } from '@/OutstreamPlayerConfig';
import { FluidPlayer } from '@/players/fluid-player/FluidPlayer';
import { FluidPlayerOptions } from './players/fluid-player/FluidPlayerOptions';

export interface OutstreamPlayerLazyLoader {
  viewableRatio?: number;
  intersectionObserverOptions?: IntersectionObserverInit;
}

export type OutstreamPlayerConfig = {
  width: number;
  height: number;
  vastTimeout: number;
  maxAllowedVastTagRedirects: number;
  allowVpaid: boolean;
  autoPlay: boolean;
  preload: boolean;
  mute: boolean;
  adText: string;
  lazyLoader?: OutstreamPlayerLazyLoader;
};

export type OutstreamPlayerConfigOptions = Partial<OutstreamPlayerConfig>;

export type PauseMode = 'completion' | 'attention';

export type StartMode = 'asap' | 'viewable';

/**
 * Use to configure the special `vastOptions.dispatchEventsOnPlayerInit` custom property
 * in FluidPlayer.
 * "asap": Events are normaly sent when they occur.
 * "viewable": Events are queued until the player is in the viewport.
 *
 * NOTE: this property is like a constant for now as we don't re-assign value.
 */
const PLAYER_START_MODE: StartMode = 'asap';

export class OutstreamPlayer {
  /**
   * Bid object response from Prebid.js
   */
  bid: BidResponse;

  /**
   * The elementId to add the player.
   * If not provided in constructor param, the class will use
   * the `adUnitElementId` param from Bid
   */
  elementId: string;

  /**
   * The DOM element related to elementId
   */
  element: HTMLElement;

  /**
   * The configuration of the Outstream Player itself
   */
  config!: OutstreamPlayerConfigOptions;

  /**
   * Instance of the Html video Player (Fluid Player)
   */
  player!: FluidPlayer;

  /**
   * The id of the element on which the Html video player will be attached
   */
  videoPlayerId!: string;

  /**
   * When the Html video player has been created
   */
  isPlayerAvailable = false;

  /**
   * The video is auto-paused when the element is not in viewport
   */
  isVideoPausedDueToScroll = false;

  /**
   * Is the player has called the player.play() and the event onPlayerPlaying
   * has been dispatched.
   */
  hasBeenPlayedOnce = false;

  /**
   * The intersectionObserver is called several thresold entries to avoid
   * to lost an intersection. By default the thresold is [0.49, 0.5, 0.51].
   * In real context this mean we could call the callback _inScreen or _outScreen
   * twice. Has this call interacts with the player.play(), this method could be
   * called twice then… which will throw an error.
   * This properties is just a flag to avoid this behavoir.
   *
   * Note: this.player.isVideoPlaying is not usable here since we could
   * call play() before it has been set.
   */
  waitForOnPlayerPlaying = false;

  /**
   * IntersectionObserver responsible of the lazy-loading of the player in Always-wiewable mode
   * Note:
   */
  lazyLoaderObserver!: IntersectionObserver;

  /**
   *
   */
  viewabilityRatioDefault = 0.5;

  /**
   * Define the mode to start/pause the player regarding the viewability/attention behavior
   * "completion": once the video is in viewport, we start the video and never pause it
   * "attention": the video is paused each time it leave the viewport
   */
  pauseMode: PauseMode = 'completion';

  // Not used for now. See `PLAYER_START_MODE = …` at the begining of this file.
  // playerStartMode: PauseMode = 'attention';

  /**
   * Used in combinaison of pauseMode "completion".
   */
  hasIntersectedOnce = false;

  /**
   * Measurer instance
   */
  attentionMeasurer!: Measurer;

  /**
   * Logger instance
   */
  logger: Logger;

  constructor(bid: BidResponse, elementId?: string, options?: OutstreamPlayerConfigOptions) {
    this.logger = Logger.getInstance();

    this.logger.debug(
      `Inside OutstreamPlayer constructor with parameters: ${JSON.stringify(bid)}, ${elementId}, ${JSON.stringify(
        options
      )}`
    );

    if (typeof bid !== 'object' || Array.isArray(bid)) {
      throw new Error('Please provide bid object.');
    }

    this.bid = bid;

    if (!this.bid.outstream) {
      throw new Error('Outstream property not found in Bid response.');
    }

    const allowedPauseMode: PauseMode[] = ['completion', 'attention'];

    if (allowedPauseMode.indexOf(this.bid.outstream.pauseMode) !== -1) {
      this.pauseMode = 'completion'; //this.bid.outstream.pauseMode;
    }

    // Check if element ID is available
    elementId = elementId || this.elementIdFromBid();
    if (!elementId) {
      this.logger.error('No element present with element ID: ' + elementId);
      throw new Error('Please provide a valid element ID.');
    }
    this.elementId = elementId;

    // Check if valid elementId
    const htmlElement = document.getElementById(this.elementId);
    if (!htmlElement) {
      this.logger.error('OutstreamPlayer has no element present with element ID: ' + elementId);
      throw new Error('Please provide a valid element ID.');
    }
    this.element = htmlElement;

    // Create config object
    this.config = new OutstreamConfig(bid, options);
    this.logger.log(`OutstreamPlayer Generic config: ${JSON.stringify(this.config)}`);

    this.player = new FluidPlayer();

    const vastOptions: PartialDeep<VastOptions> = {
      dispatchEventsOnPlayerInit: PLAYER_START_MODE === 'asap' ? true : false,
      vastAdvanced: {
        vastLoadedCallback: (): void => {
          this.logger.debug('vast loaded');
        },
        vastVideoEndedCallback: (): void => {
          this.logger.debug('vastVideoEndedCallback - called');
          if (this.attentionMeasurer) {
            this.logger.debug('vastVideoEndedCallback - detach attention measurer');
            this.attentionMeasurer.detach();
          }

          const htmlElement = document.getElementById(this.elementId);
          if (htmlElement) {
            this.logger.debug('vastVideoEndedCallback - remove element from DOM.');
            const pw: HTMLElement | null = htmlElement.querySelector('div[id^=fluid_video_wrapper_videoPlayer]');
            if (pw) {
              pw.style.transition = 'all 0.3s ease';
              pw.style.overflow = 'hidden';
              pw.style.height = '0px';

              setTimeout(() => {
                htmlElement.style.display = 'none';
              }, 350);
            } else {
              htmlElement.style.display = 'none';
            }
          }
        }
      }
    };
    this.player.generatePlayerConfig(this.bid, this.elementId, { vastOptions });

    this.insertPlayer();
  }

  insertPlayer(): void {
    if (window.IntersectionObserver) {
      // Always setup the player.
      this.setupPlayer();

      // Then bind the measurer related to the startMode
      if (PLAYER_START_MODE === 'viewable') {
        this.logger.debug('OutstreamPlayer use Attention mode.');
        try {
          this._buildLazyLoaderObserver();
        } catch (err) {
          this.logger.error(err);
        }
      } else {
        this.bindAttentionMeasurer();
      }
    }
  }

  bindAttentionMeasurer() {
    try {
      this.logger.debug('OutstreamPlayer bind measurer');

      const { viewableRatio, threshold, rootMargin } = this.getBidOutstreamLazyConfig();

      this.attentionMeasurer = new Measurer(this.element, {
        beaconUrl: this.bid.outstream?.bvwUrl || '', // outstream will always been there, we already check its presence in the constructor
        onVisibilityChange: (hidden: boolean, isIntersecting: boolean) => {
          if (hidden) {
            this._outScreen();
          } else {
            this._onScreen();
          }
        },
        onIntersecting: (isIntersecting: boolean) => {
          if (isIntersecting === true) {
            this._onScreen();
          } else {
            this._outScreen();
          }
        },
        // Keep this commented voluntary. Do not remove.
        // -
        // onBeforeSendBeacon: (becauseOf: string, isIntersecting: boolean) => {
        //   return {
        //     additionalProp: 'value'
        //   };
        // },
        viewableRatio,
        observerOptions: this.config.lazyLoader?.intersectionObserverOptions || {
          threshold,
          rootMargin
        }
      });
    } catch (err) {
      this.logger.error(err);
    }
  }

  onPlayerPlaying = () => {
    this.waitForOnPlayerPlaying = false;

    if (this.hasBeenPlayedOnce) {
      this.logger.debug('Skip onPlayerPlaying event');
      return;
    }

    this.logger.debug('OutstreamPlayer onPlayerPlaying callback');

    this.hasBeenPlayedOnce = true;

    if (!this.attentionMeasurer || !this.attentionMeasurer.isIntersecting) {
      this.player.pause();
      return;
    }
  };

  setupPlayer(): void {
    this.logger.debug('OutstreamPlayer setupPlayer method.');

    // Once set, do not unset it
    this.isPlayerAvailable = true;
    this.element.style.display = 'block';
    this.insertVideoElement();
    this.player.setupPlayer(this.videoPlayerId);

    // Event "playing" is related to the play() function.
    // It means we call play(), the function will launch/call the vast
    // assign the video source from the VAST to the <video> element.
    // As the video is assign, VAST events start and impression are sent,
    // then this event is emitted.
    this.player.player.on('playing', this.onPlayerPlaying);
    this.player.play();
  }

  insertVideoElement(): void {
    const divAdunit = this.element;
    // create ID for video element
    this.videoPlayerId = `videoPlayer-${this.elementId}`;

    if (divAdunit) {
      // create video element
      let videoElement = `<video id="${this.videoPlayerId}" style="width: ${this.config.width}px; height: ${
        this.config.height
      }px" \
        ${this.config.autoPlay ? 'autoplay="true"' : ''} \
        ${this.config.mute ? 'muted="muted"' : ''}></video>`;

      // The `imp` beacon
      const impSrc = this.bid.outstream?.impUrl;
      if (impSrc) {
        videoElement += `<img src="${impSrc}" width="0" height="0" style="display: none" />`;
      }

      this.logger.log(`OutstreamPlayer has generated video element string: ${videoElement}`);
      divAdunit.insertAdjacentHTML('beforeend', videoElement);
    } else {
      throw new Error(`No element is present with provided element ID: ${this.elementId}`);
    }
  }

  elementIdFromBid(): string | undefined {
    return Array.isArray(this.bid?.params) ? this.bid?.params[0]?.adUnitElementId : undefined;
  }

  private _onScreen() {
    this.logger.debug('OutstreamPlayer inside _onScreen');

    this.hasIntersectedOnce = true;

    if (this.isPlayerAvailable) {
      if (document.hidden) {
        this.logger.debug('OutstreamPlayer, the document is in hidden state');
        return;
      }
      // Player is already visible
      if (!this.waitForOnPlayerPlaying && !this.player.isVideoPlaying) {
        this.logger.log('OutstreamPlayer, Player is not playing. Resume video.');

        this.waitForOnPlayerPlaying = true;
        this.player.player.dispatchEnqueuedVastEvents();
        this.player.play();

        if (this.isVideoPausedDueToScroll) {
          // Unset the flag as video is now resumed
          this.isVideoPausedDueToScroll = false;
        }
      }
    } else {
      this.player.play();
    }
  }

  private _outScreen() {
    this.logger.debug('OutstreamPlayer inside _outScreen');
    // Do nothing if player is in "completion" pauseMode
    if (this.pauseMode === 'completion') {
      if (this.hasIntersectedOnce) {
        this.logger.debug(`completion mode. Intersected once: ${this.hasIntersectedOnce}`);
        // if (this.pauseMode === 'completion') {
        return;
      } else {
        // The play method is called to launch the firsts VAST events: start and impression.
        // Then the onPlayerPlaying event callback is executed and apply player.pause() if
        // the player does not intersect
        if (!this.waitForOnPlayerPlaying && !this.player.isVideoPlaying) {
          this.player.play();
        }
      }
    }

    // Check if video is playing
    if (this.isPlayerAvailable && this.player.isVideoPlaying) {
      this.logger.log('OutstreamPlayer, Player is not on screen, pause the video.');
      this.player.pause();
      // Set the flag that video is paused due to scrolling
      this.isVideoPausedDueToScroll = true;
    }
  }

  private _buildLazyLoaderObserver(): void {
    const { viewableRatio, threshold, rootMargin } = this.getBidOutstreamLazyConfig();

    this.lazyLoaderObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          try {
            // const ratio = Math.round((entry.intersectionRatio + Number.EPSILON) * 100) / 100;
            if (entry.intersectionRatio >= viewableRatio) {
              this.lazyLoaderObserver.unobserve(this.element);
              this.bindAttentionMeasurer();
            }
          } catch (err) {
            this.logger.error(err);
          }
        });
      },
      {
        rootMargin,
        threshold
      }
    );

    this.lazyLoaderObserver.observe(this.element);
  }

  getBidOutstreamLazyConfig(): BidOutstreamLazyLoader {
    const { outstream } = this.bid;

    // Le threshold devrait être systématiquement un tableau
    // afin d'être sur de ne pas louper une intersection.
    const computedThreshold = (adjustment: number = 0.01) => {
      let threshold = outstream?.lazyLoader?.threshold;

      if (threshold) {
        if (Array.isArray(threshold)) {
          return threshold;
        } else if (typeof threshold === 'number') {
          return [threshold - adjustment, threshold, threshold + adjustment];
        }
      }

      return [
        this.viewabilityRatioDefault - adjustment,
        this.viewabilityRatioDefault,
        this.viewabilityRatioDefault + adjustment
      ];
    };

    const computeViewableRatio = () => {
      let viewableRatio = outstream?.lazyLoader?.viewableRatio;

      if (!viewableRatio) {
        const threshold = outstream?.lazyLoader?.threshold;
        if (threshold) {
          if (Array.isArray(threshold)) {
            throw new Error(
              'Threshold in lazyLoader options has been set as an Array and cannot be used to define viewableRatio. \
              Set a `viewableRatio` in options or use a number for `threshold` value'
            );
          }
          viewableRatio = threshold;
        } else {
          viewableRatio = this.viewabilityRatioDefault;
        }
      }
      return viewableRatio;
    };

    const rootMargin = outstream?.lazyLoader?.rootMargin || '';
    const threshold = computedThreshold();

    // viewableRatio devrait être passé dans les options du lazyLoader mais actuellement
    // le SSP considère le "viewableRatio" comme étant le "thresold".
    const viewableRatio = computeViewableRatio();

    return {
      rootMargin,
      threshold,
      viewableRatio
    };
  }
}
