import defaultsDeep from 'lodash/defaultsDeep';
import { FluidPlayerOptions as IFluidPlayerOptions, LayoutControlsOptions, VastOptions } from 'fluid-player';

export class FluidPlayerOptions {
  vastOptions!: VastOptions;

  layoutControls!: LayoutControlsOptions;

  constructor(options: IFluidPlayerOptions) {
    const _defaults: IFluidPlayerOptions = {
      layoutControls: {
        playButtonShowing: true,
        playPauseAnimation: true,
        allowTheatre: false,
        doubleclickFullscreen: false,
        controlBar: {
          autoHide: true,
          autoHideTimeout: 0.1
        }
      },
      vastOptions: {
        allowVPAID: true, // Default false.
        showProgressbarMarkers: false,
        skipButtonCaption: 'Skip ad in [seconds]',
        skipButtonClickCaption: 'Skip ad <span class="skip_button_icon"></span>',
        vastTimeout: 3000,
        showPlayButton: false,
        maxAllowedVastTagRedirects: 3,
        vastAdvanced: {
          vastLoadedCallback: () => {},
          noVastVideoCallback: () => {},
          vastVideoSkippedCallback: () => {},
          vastVideoEndedCallback: () => {}
        }
      }
    };

    const config: IFluidPlayerOptions = defaultsDeep(options, _defaults);

    if (config.vastOptions) {
      this.vastOptions = config.vastOptions;
    }

    if (config.layoutControls) {
      this.layoutControls = config.layoutControls;
    }
  }
}
